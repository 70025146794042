import React, { useEffect, useState } from 'react'
import Customer from './customer'
import { useNavigate } from 'react-router-dom'
import { fetchRequest } from '../../util/api'
import { setCustomers } from '../../state'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../shared/table'
import {apiRequest} from '../../util/api'; 

const Customers = () => {
  const url = "listcustomers"

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const customers = useSelector(state=>state.global.customers)
  const headerList = ['customer Name','Email','Customer Id','Initial Login','Action']

  const [showPopup, setShowPopup] = useState(false); 
  
  function updateList(){
    fetchRequest(url).then(data => dispatch(setCustomers(data)))
  }

  useEffect(() => {
    // Prüfe, ob alle Listen leer sind
    Promise.all([
      fetchRequest("listcustomers"),
      fetchRequest("listprojects"),
      fetchRequest("listmeasurands")
    ])
    .then(([customers, projects, measurands]) => {
      if (customers === null && projects === null && measurands === null) {
        setShowPopup(true);
      }
    })
    .catch(error => console.error("Error checking database:", error));
  }, []);

  const handleCreateExamples = () => {
    apiRequest("createExamples", "POST")
      .then(() => {  
        setShowPopup(false);
        updateList(); 
      })
      .catch(error => console.error("Error creating examples:", error));
  };

  return (
    <section className="page-container">
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg text-center">
            <h2 className="text-lg font-bold mb-4">No Data Found</h2>
            <p>It looks like there are no customers, projects, or measurands in the database.</p>
            <button onClick={handleCreateExamples} className="mt-4 px-4 py-2 bg-green-500 text-white rounded">
              Create Examples
            </button>
          </div>
        </div>
      )}

      <Table
        heading={"Customers"}
        dataList={customers}
        component={(idx, customer) => <Customer key={idx} customer={{ customerData: customer }} handler={updateList} />}
        buttonContent={"Add Customer"}
        handler={() => navigate('/addcustomer')}
        headerList={headerList}
      />
    </section>
  );
};

export default Customers