import React, { useState } from 'react'
import ErrorComponent from '../shared/error';
import { useLocation, useNavigate } from 'react-router-dom';
import { postMethod } from '../../util/shared';
import { apiRequest } from '../../util/api';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../shared/label';
import Input from '../shared/input';
import Dropdown from '../shared/dropdown';
import ListElement from '../shared/listElement';
import { fetchMeasurandCategories, fetchMeasurands } from '../../util/stateChanges';
import Button from '../shared/button';

const AddMeasurand = () => {
    const measurandCategories = useSelector(state=>state.global.measurandCategories)
    const dropDownList = [{measurandCategoryName:"Type your Own"}, ...measurandCategories]
    const [measurandCategoryName, setMeasurandCategoryName] = useState("Please Select Measurand Category");
    const [name, setName] = useState("");
    const [unit,setUnit] = useState("");
    const [displayError, display] = useState(false);
    const [activeElementType,setActiveElementType] = useState("dropdown");
    const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
    const [showMeasurands, setShowMeasurands] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()
    function toggleSelectMeasurand(){
      setShowMeasurands(!showMeasurands)
    }
  
    function dropDownChanged(mc) {
      toggleSelectMeasurand()
      
  
      setMeasurandCategoryName(mc.measurandCategoryName)
      if (mc.measurandCategoryName === "Type your Own") {
        setActiveElementType({ activeElementType: "input" });
        setMeasurandCategoryName("")
      }
    }
   
    
    function addmeasurand(e){
      
        e.preventDefault();
        if(measurandCategoryName==null){
          setErrorMessage("Please Select A Valid Measurand Category!")
          display(true)
          return
        }
        const body = JSON.stringify({
          measurandName:name,
          unit: unit,
          measurandCategoryName: measurandCategoryName
          })

      apiRequest('createmeasurand',postMethod,body).then((data) => {
        if(data.measurandId != null){
          fetchMeasurands(dispatch)
          fetchMeasurandCategories(dispatch)
          navigate(
              location?.state?.previousUrl
                  ? location.state.previousUrl
                  : '/measurands'
          )
        }
        else {
          setErrorMessage(msg=>"Unable to create measurand")
          display(true)
        }
          }).catch(e=>{
            display(true)
            setErrorMessage(e)
          })
    }
  return (

    <form className="space-y-4 md:space-y-6" id="measurand" onSubmit={addmeasurand}>
    {displayError && <ErrorComponent message={{e:errorMessage}}/>}
        <div>
            <Label content={"Measurand Name"} labelFor={"name"} />
            <Input id={"name"} handler={(e) => {setName(e.target.value)}} placeholder={"Measurand Name"} value={name} type={"text"} />
        </div>
        <div>
            <Label content={"Unit"} labelFor={"unit"} />
            <Input id={"unit"} handler={(e) => {setUnit(e.target.value)}} placeholder={"Measurand Unit"} value={unit} type={"text"} />
          
            
        </div>
        <div>
        <span className="span-container">Measurand Category</span>
          
          { activeElementType==="dropdown" || measurandCategories.length===0?
          <div className='relative w-full'>
          <Dropdown content={measurandCategoryName} handle={toggleSelectMeasurand} />
          { 
            showMeasurands && measurandCategories && measurandCategories.length > 0 && 
            <div id="dropdown" className="bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-600 absolute z-[1] w-full overflow-y-scroll max-h-32 no-scrollbar">
            <div className="py-2 text-sm text-gray-700 dark:text-gray-200">
            { dropDownList.map((mc,idx)=><ListElement name={mc.measurandCategoryName} element={mc} key={idx} handle={dropDownChanged} />)}
            </div>
            </div>
          }
          </div>
          
          :
          <Input id={"measurandCategory "} value={measurandCategoryName} type={"text"} handler={e => {setMeasurandCategoryName(e.target.value)}} placeholder={"Type Custom Measurand Category"} />
          
          }
        </div> 
        <div className="flex items-center justify-between">
           
        </div>
        <Button content={"Add Measurand"} />
    </form>

  )
  }

export default AddMeasurand