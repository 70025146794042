import React, { useState } from 'react';
import { baseUrl, postMethod } from '../../util/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorComponent from '../shared/error';
import Tag from '../shared/tag';
import Button from '../shared/button';
import { useDispatch, useSelector } from 'react-redux';
import Label from '../shared/label';
import Input from '../shared/input';
import ListElement from '../shared/listElement';
import { fetchProjectCategories } from '../../util/stateChanges';
import { setToast } from '../../state';

const AddProjectCategory = () => {
  const token = localStorage.getItem("access");
  const [name, setName] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [displayError, display] = useState(false);
  const [selectedMeasurandList, setSelectedMeasurandList] = useState([]);
  const allMeasurands = useSelector(state => state.global.measurands);
  const [filteredMeasurands, setFilteredMeasurands] = useState(allMeasurands);
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  function unSelectMeasurand(measurand) {
    setSelectedMeasurandList(selectedMeasurandList.filter(x => x.measurandId !== measurand.measurandId));
    setFilteredMeasurands(prev => [...prev, measurand]); // Measurand zurück in die Liste setzen
  }

  function selectMeasurand(measurand) {
    setFilteredMeasurands(filteredMeasurands.filter(x => x.measurandId !== measurand.measurandId)); // Entfernen aus Liste
    setSelectedMeasurandList([...selectedMeasurandList, measurand]);
    setSearchText(""); 
    setFilteredMeasurands(prev => prev); 
  }

  function handleSearch(e) {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    setFilteredMeasurands(allMeasurands.filter(m => 
      !selectedMeasurandList.includes(m) && m.measurandName.toLowerCase().includes(value)
    ));
    setShowMenu(true); 
  }

  function addProjectCategory(e) {
    e.preventDefault();
    const url = baseUrl + 'createprojectcategory';
    let measurandIds = selectedMeasurandList.map(x => x.measurandId);

    fetch(url, {
      method: postMethod,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        projectCategoryName: name,
        measurands: measurandIds
      }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.projectCategoryId != null) {
          fetchProjectCategories(dispatch);
          dispatch(setToast({ message: "Project Category Successfully Created!", success: true }));
          navigate(location?.state?.previousUrl ? location.state.previousUrl : '/projectcategories');
        } else {
          setErrorMessage("Unable to create project category");
          display(true);
        }
      }).catch(e => {
        display(true);
        setErrorMessage(e);
      });
  }

  return (
    <form className="space-y-4 md:space-y-6" id="measurand" onSubmit={addProjectCategory}>
      {displayError && <ErrorComponent message={{ e: errorMessage }} />}
      <div>
        <Label labelFor={"name"} content={"Name"} />
        <Input id={"name"} type={"text"} value={name} handler={(e) => setName(e.target.value)} placeholder={"Project Category Name"} />
      </div>

      <div className="w-full sm:mb-2 relative">
        <span className="span-container">Measurands</span>
        <input
          type="text"
          className="inline-flex mt-3 py-3 px-5 w-full border-2 border-purple-300 rounded outline-none placeholder:text-gray-400 
            peer dark:bg-gray-500 dark:text-gray-200 dark:placeholder-text-gray-300 dark:border-gray-400 cursor-pointer"
          placeholder="Search or select a Measurand..."
          value={searchText}
          onChange={handleSearch}
          onFocus={() => { setShowMenu(true); setFilteredMeasurands(allMeasurands.filter(m => !selectedMeasurandList.includes(m))); }} // Nur noch verbleibende anzeigen
          onBlur={() => setTimeout(() => setShowMenu(false), 200)} // Leicht verzögertes Schließen
        />

        {showMenu && (
          <div id="dropdown" className="bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-600 absolute z-[1] w-full">
            <div className="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-32 overflow-y-auto">
              {filteredMeasurands.map((mc, idx) => (
                <ListElement name={`${mc.measurandName} (${mc.measurandCategoryName || 'Unknown'})`} element={mc} key={idx} handle={selectMeasurand} />
              ))}
            </div>
          </div>
        )}
      </div>

      <div>
        <div className='px-2 pt-2 pb-11 mb-3 flex flex-wrap rounded-lg bg-purple-200 dark:bg-gray-400'>
          {selectedMeasurandList.map((measurand, idx) => <Tag key={idx} handle={unSelectMeasurand} measurand={measurand} />)}
        </div>
      </div>

      <Button content={"Add Project Category"} />
    </form>
  );
}

export default AddProjectCategory;

