import React from 'react'
import logo from '../../Folivora_Icon-white-green-linear_gradient_optimized.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { setLogout } from '../../state';


const NavItem = ({label, path, currentPath, navigate}) => {
  return (
    <li>
      <button
        onClick={() => navigate(path)}
        className={`nav-link ${currentPath === path ? 'nav-link-activate' : ''}`}
      >
        {label}
      </button>
    </li>
  )
}


const Nav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const loc = window.location.href.split('/')[3]
  const currentPath = location.pathname

  function logout(){
    localStorage.removeItem('access')
    localStorage.removeItem('adminId')
    dispatch(setLogout())
    
    navigate('/login')
  }
  return (
    
<nav className="nav-container">
<div className="nav-wrapper">
  <a href="http://folivoraenergy.com" className="flex items-center">
      <img src={logo} className="h-8 mr-3" alt="Flowbite Logo" />
      <span className="self-center text-2xl font-semibold whitespace-nowrap text-white dark:text-white">Folivora Energy</span>
  </a>
  {/* Navigation Links */}
  <div className="hidden w-full md:block md:w-auto" id="navbar-default">
    <ul className="nav-menu">
      <NavItem label="Customers" path="/customers" currentPath={currentPath} navigate={navigate} />
      <NavItem label="Projects" path="/projects" currentPath={currentPath} navigate={navigate} />
      <NavItem label="Measurands" path="/measurands" currentPath={currentPath} navigate={navigate} />
      <NavItem label="Project Categories" path="/projectcategories" currentPath={currentPath} navigate={navigate} />
      <NavItem label="Admins" path="/admins" currentPath={currentPath} navigate={navigate} />

      <li>
        <button onClick={()=>logout()} className="nav-link">Logout</button>
      </li>
    </ul>
  </div>
 
</div>
</nav>

  )
}

export default Nav