import React from 'react'
import Button from './button'

const Table = ({heading,dataList,component,headerList, handler, buttonContent}) => {
  return (
    <div className="table-container">
    <div className="table-wrapper">
    <div className="table-header">
    <div className="table-heading">{heading}</div>
    <div className="table-button-container">
    <Button handle={handler} content={buttonContent} />
    </div>
    
</div>
    <div className="table-scroll-container">
    <table className="table-layout">
        <thead className="table-head">
            <tr className="table-row-header">
            {
                headerList && headerList.length>0 && headerList.map((headerName,idx)=><th key={idx} className="px-4 py-3 w-1/2">{headerName}</th>)
            }

            </tr>
        </thead>
        <tbody className="table-body">

        { 
          dataList && dataList.length > 0 && dataList.map((project,idx)=>
            
            component(idx, project)
          )
        }
    
    </tbody>
    </table>
</div>
 </div>
 </div>      
  )
}

export default Table 