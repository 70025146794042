import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Table from '../shared/table';
import { fetchProjectCategoryData } from '../../util/stateChanges';
import Measurand from '../measurands/measurand';

const ViewProjectCategory = () => {
  const { id } = useParams();
  const [projectCategory, setProjectCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const allMeasurands = useSelector(state => state.global.measurands);
  const filteredMeasurands = allMeasurands.filter(measurand =>
    projectCategory?.measurands?.includes(measurand.measurandId)
  );
  const measurandCount = filteredMeasurands.length; 
  const headerList = ['Measurand Name', 'Measurand ID', 'Measurand_Category Name', 'Action'];
  const popupHeaderList = ['Measurand ID', 'Measurand Name', 'Measurand Category Name'];

  useEffect(() => {
    setLoading(true);
    fetchProjectCategoryData(id).then(data => {
      setProjectCategory(data);
      setLoading(false);
    });
  }, [id]);

  const findExactDuplicates = () => {
    const duplicates = new Map();
    
    filteredMeasurands.forEach(measurand => {
      const key = `${measurand.measurandName}_${measurand.measurandCategoryName}`;

      if (!duplicates.has(key)){
        duplicates.set(key, []); 
      }
      duplicates.get(key).push(measurand); 
    }); 
    return Array.from(duplicates.values()).filter(group => group.length > 1); 
  }

  const exactDuplicateMeasurands = new Set();
  findExactDuplicates().forEach(group => {
    if (group.length > 1) {
      group.forEach(measurand => exactDuplicateMeasurands.add(measurand.measurandId));
    }
  });

  

  const groupMeasurandsByName = () => {
    const groupedMeasurands = {};

    filteredMeasurands.forEach(measurand => {
      if (!groupedMeasurands[measurand.measurandName]) {
        groupedMeasurands[measurand.measurandName] = [];
      }
      groupedMeasurands[measurand.measurandName].push(measurand);
    });

   
    return Object.values(groupedMeasurands).filter(group => group.length > 1);
  };

  return (
    <section className="page-container">
      <div className="view-container">
        <div className="view-card">
          <div className="container">
            <div className="view-card-header">
              <div className="view-card-title">
                Project Category Details
              </div>
            </div>
            <div className="view-details-row">
              <div className="view-details-label">
                Category Name
              </div>
              <div className="view-details-value">
                {projectCategory?.projectCategoryName || 'Loading...'}
              </div>
            </div>
            <div className="view-details-row">
              <div className="view-details-label">
                Category ID
              </div>
              <div className="view-details-value">
                {projectCategory?.projectCategoryId || 'Loading...'}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(() => {
        let e = (
          <Table
            heading={`Measurands (${measurandCount})`}
            dataList={filteredMeasurands}
            component={(idx, measurand) => (
              <Measurand key={idx} measurand={{ measurandData: measurand }} />
            )}
            buttonContent={'Check Duplicate Measurands'}
            handler={() => setShowPopup(true)}
            headerList={headerList}
          />
        );
        return filteredMeasurands && filteredMeasurands.length > 0 ? (
          e
        ) : (
          <div className="text-gray-100 font-bold"> No Projects Yet..</div>
        );
      })()}

      {/* **Popup for Measurands-Check** */}
      {showPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h2 className="text-lg font-bold mb-4">Duplicate Measurands</h2>
            <p className="text-gray-600 mb-4">
              The following Measurands have duplicate names within this project category.
              Verify that they are correctly assigned to the right category.
            </p>
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr className="bg-gray-200">
                  {popupHeaderList.map((header, idx) => (
                    <th key={idx} className="border border-gray-300 px-4 py-2">
                      {header}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {groupMeasurandsByName().map((group, idx) => (
                  <React.Fragment key={idx}>
                    {group.map((measurand, index) => (
                      <tr
                      key={index}
                      className={`border border-gray-300 ${
                        exactDuplicateMeasurands.has(measurand.measurandId) ? 'bg-red-400' : ''
                      }`}
                    >
                        <td className="border border-gray-300 px-4 py-2">{measurand.measurandId}</td>
                        <td className="border border-gray-300 px-4 py-2">{measurand.measurandName}</td>
                        <td className="border border-gray-300 px-4 py-2">{measurand.measurandCategoryName}</td>
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
            <button
              onClick={() => setShowPopup(false)}
              className="mt-4 bg-red-500 text-white px-4 py-2 rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default ViewProjectCategory;
