import React, { useEffect, useState } from 'react'
import Button from '../shared/button';
import Dropdown from '../shared/dropdown';
import Label from '../shared/label';
import ListElement from '../shared/listElement';
import Input from '../shared/input';
import ErrorComponent from '../shared/error';
import { baseUrl, putMethod, postMethod } from '../../util/shared';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProjects, fetchProjectSettings } from '../../util/stateChanges';
import { apiRequest } from '../../util/api';

const UpdateProject = () => {
    const {id} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const token = localStorage.getItem("access")
    const dispatch = useDispatch()

    const projectCategories = useSelector(state=>state.global.projectCategories)
    const project = useSelector(state=>state.global.projects).find(x=>Number(x.projectId)===Number(id))
    const customers = useSelector(state=>state.global.customers)

    const [hardware, setHardware] = useState("Loading..");
    const [projectCategoryId, setProjectCategoryId] = useState();
    const [projectCategory, setProjectCategory] = useState("Loading..");
    const [name, setName] = useState("Loading..");
    const [password,setPassword] = useState("");
    const [displayError, display] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
    const [showProjectCategories,setShowProjectCategories] = useState(false)
    const [showCustomers,setShowCustomers] = useState(false)
    const [customer,setCustomer] = useState("Loading...")
    const [customerId,setCustomerId] = useState()

    const [projectSettings, setProjectSettings] = useState({}); 
    const [projectSettingsSlot2, setProjectSettingsSlot2] = useState(""); 


    function selectProjectCategory(pc){
       setProjectCategoryId(pc.projectCategoryId)
       setProjectCategory(pc.projectCategoryName)
       toggleSelectProjectCategory()
    }

    function selectCustomer(c){
        setCustomerId(c.customerId)
        setCustomer(c.name)
        toggleSelectCutomers()
     }

     function toggleSelectCutomers(){
      setShowProjectCategories(false)
      setShowCustomers(!showCustomers)
    }

    function toggleSelectProjectCategory(){
      setShowCustomers(false)
      setShowProjectCategories(!showProjectCategories)
        
    }


   useEffect(()=>{
    if(project && customers){
      setProjectCategory(project.projectCategoryName)
      setProjectCategoryId(project.projectCategoryId)
      setHardware(project.hardware)
      setName(project.projectName)
      setCustomer(customers.find(x=>Number(x.customerId)===Number(project.customerId)).name)
      setCustomerId(project.customerId)
    }

    fetchProjectSettings(id).then(settings => {
      setProjectSettings(settings || {});
      setProjectSettingsSlot2(settings?.projectSettingsSlot2 || ""); // Hier setzen wir den Wert in den Input
    });

   },[project,customers])
    
    function addproject(e){
        e.preventDefault();
        if(projectCategoryId==null){
          setErrorMessage("Please Select A Valid Project Category!")
          display(displayError=>true)
          return
        }
        if(customerId==null){
            setErrorMessage("Please Select A Valid Customer!")
          display(displayError=>true)
          return
        }
        if(password===""){
            setErrorMessage("Please Enter A valid Password")
          display(displayError=>true)
          return
        }
      
      const url = 'updateproject/'+id;
      const body= JSON.stringify({
        projectName:name,
        customerId: customerId,
        password: password,
        hardware: hardware,
        projectCategoryId: projectCategoryId,
      }); 

      apiRequest(url, putMethod, body)
      .then((data) => {
        if(data.projectId != null){
          fetchProjects(dispatch);
          updateProjectSetttings(data.projectId);
          navigate(
              location?.state?.previousUrl
                  ? location.state.previousUrl
                  : '/projects'
          );
        }
        else {
          setErrorMessage(msg=>"Unable to update project")
          display(displayError=>true)
        }
        
        
    }).catch(e=>{
      display(displayError=>true)
      setErrorMessage(msg=>e)
    });
    }

  function updateProjectSetttings(projectId) {
    if (!projectSettingsSlot2) {
      setErrorMessage("Project settings Slot 2 value is missing!"); 
      display(true);
      return;
  }
    const url = 'setprojectsettings'; 
    const body = JSON.stringify({
      projectId: projectId, 
      slot: 2,
      value: projectSettingsSlot2,
    });

    apiRequest(url, postMethod, body)
    .then((response) => {
      if (!response) {
        throw new Error ("Failed to update project settings"); 
      }
    })
    .catch((e) => {
      setErrorMessage(e.message);
      display(true);
    })
  }
  return (
  <form className="space-y-4 md:space-y-6" id="project" onSubmit={addproject}>
    {displayError && <ErrorComponent message={{e:errorMessage}}/>}
    <div>
      <Label labelFor={"name"} content={"Project Name"} />
      <Input id={"name"} value={name} placeholder={"Project Name"} handler={(e)=>setName(e.target.value)} type={"text"} />
    </div>
    <div>
      <Label labelFor={"password"} content={"Password"} />
      <Input id={"password"} value={password} placeholder={"Password"} handler={(e)=>setPassword(e.target.value)} type={"password"} />
    </div>
    <div>
      <Label labelFor={"hardware"} content={"Hardware"} />
      <Input id={"hardware"} value={hardware} placeholder={"Hardware"} handler={(e)=>setHardware(e.target.value)} type={"text"} />
    </div>
    
    <div className="w-full sm:mb-2 relative">
    <span className="span-container">Project Category</span>
      <Dropdown content={projectCategory} handle={toggleSelectProjectCategory} />
      { showProjectCategories && projectCategories && projectCategories.length > 0 && 
        <div id="dropdown" className="bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-600 absolute z-[1] w-full overflow-auto max-h-32 no-scrollbar">
        <div className="py-2 text-sm text-gray-700 dark:text-gray-200">
        {projectCategories && projectCategories.map((pc,idx)=><ListElement name={pc.projectCategoryName} element={pc} key={idx} handle={selectProjectCategory} />)}
        </div>
        </div>
     }
    </div>
    <div className="w-full sm:mb-2 relative pb-4">
    <span className="span-container">Customer</span>
    <Dropdown content={customer} handle={toggleSelectCutomers} />
    { showCustomers && projectCategories && projectCategories.length > 0 && 
      <div id="dropdown" className="bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-600 absolute z-[1] w-full overflow-auto max-h-28 no-scrollbar">
      <div className="py-2 text-sm text-gray-700 dark:text-gray-200">
      {customers && customers.map((pc,idx)=><ListElement name={pc.name} element={pc} key={idx} handle={selectCustomer} />)}
      </div>
      </div>
   }

    <div className="pt-6">
      <Label labelFor={"projectsettings_slot2"} content={"Project Settings Slot 2"} />
      <Input id={"projectsettingsSlot2"} value={projectSettingsSlot2} placeholder={"Project Settings Slot 2"} handler={(e)=>setProjectSettingsSlot2(e.target.value)} type={"text"} />
    </div>
  </div>
    <Button content={"Update Project"} />
  </form>
  )
}

export default UpdateProject