import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Project from '../projects/project'
import Table from '../shared/table'

const ViewCustomer = () => {
    const {id} = useParams()

  const customer = useSelector(state=>state.global.customers).find(x=>Number(x.customerId)===Number(id))
  const projects = useSelector(state => state.global.projects) || [] 
  const filteredProjects = projects.filter(x => Number(x.customerId) === Number(id));
  const navigate = useNavigate()
  const headerList = ['Project Name','Project Id','Customer Id','Category','Hardware','Action']
  
  return (
    <section className='page-container'>
        <div key={1} className='view-container'>
          <div className="view-card">
            <div className="container">
                <div className="view-card-header">
                    <div className="view-card-title">
                        Customer Details
                    </div>
                </div>
       
            <div className="view-details-row">
            <div className='view-details-label'>Customer Name</div>
            <div className='view-details-value'>{customer?.name ?? "Loading.."}</div>
        </div>
            <div className="view-details-row">
                <div className='view-details-label'><p>Customer ID</p></div>
                <div className='view-details-value'>{customer? customer.customerId: "Loading.."}</div>
            </div>
            <div className="view-details-row">
                <div className='view-details-label'><p>Email</p></div>
                <div className='view-details-value'>{customer? customer.email: "Loading.."}</div>
            </div>
  
            </div>
          </div>

        </div>
        {(()=>{
            let e = <Table heading={"Projects"} dataList={projects} component={(idx,project)=><Project key={idx} project={{projectData:project}} />} buttonContent={"Add Project"} handler={()=>navigate('/addproject')} headerList={headerList} />
            return (filteredProjects.length > 0) ? e:<div className='text-gray-100 font-bold'> No Projects Yet..</div>
       
        })()
        }
        
    

    </section>
  )
}

export default ViewCustomer