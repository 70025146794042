import React, {  useState } from 'react'
import ProjectCategory from './projectCategory'
import { useNavigate } from 'react-router-dom'
import {  useSelector} from 'react-redux'

import Toast from '../shared/toast'
import Table from '../shared/table'

const ProjectCategories = () => {
  
  const navigate = useNavigate()
  const [showToast,setShowToast] = useState(false)
  const projectCategories = useSelector(state=>state.global.projectCategories)
  const headerList = ['Name', "Id", "Action"]
  function show(){
    setShowToast(true)
  }
  function hide(){
    setShowToast(false)
  }

  return (
   
      <section className='page-container'>
      {   showToast && <div className='flex flex-col items-center'><Toast handler={hide}/></div> }
        <Table heading={"Project Categories"} dataList={projectCategories} component={(idx,pc)=><ProjectCategory key={idx} projectCategory={{projectCategoryData:pc}}  toastFunction={show}/>} buttonContent={"Add Project Category"} handler={()=>navigate('/addprojectcategory')} headerList={headerList} />
      </section>
  
    
  )
}

export default ProjectCategories