import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Nav from '../nav/nav'
import { useDispatch, useSelector } from 'react-redux'
import { fetchAdmins, fetchCustomers, fetchMeasurandCategories, fetchMeasurands, fetchProjectCategories, fetchProjects } from '../../util/stateChanges'
import Toast from '../shared/toast'
import { setToast } from '../../state'
import { fetchRequest } from '../../util/api'
import Modal from '../shared/modal'
import { generateUsedMeasurands, generateUsedProductCategories } from '../../util/usedDict'


const Layout = () => {
  const dispatch = useDispatch()
  const toast = useSelector(state=>state.global.toast)
  const [toastDisplay,setToastDisplay] = useState({})
  const modal = useSelector(state=>state.global.modal)
  const projects = useSelector(state=>state.global.projects)
  const projectCategories = useSelector(state=>state.global.projectCategories)
  useEffect(()=>{
    setInterval(()=>{
      fetchRequest('refreshadmintoken').then(data=>{
        localStorage.setItem("access",data.access_token)
      })
    },1000*60*15)
  },[])

  useEffect(()=>{
    let token = localStorage.getItem("access")
    if(token!==null && token !== "undefined" && projects){
      generateUsedProductCategories(projects,dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[projects])
  useEffect(()=>{
    let token = localStorage.getItem("access")
    if(token!==null && token !== "undefined" && projectCategories){
      generateUsedMeasurands(projectCategories,dispatch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[projectCategories])

  useEffect(()=>{
    let token = localStorage.getItem("access")
    if(token!==null && token !== "undefined"){
      fetchMeasurands(dispatch)
      fetchCustomers(dispatch)
      fetchProjectCategories(dispatch)
      fetchProjects(dispatch)
      fetchAdmins(dispatch)
      fetchMeasurandCategories(dispatch)
      setToastDisplay(toast)

    }
    
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[toast])
  return (
    <div className="flex flex-col bg-primary-main-background dark:bg-gray-900 min-h-screen max-h-full">
 
      <Nav/>
      {
        (()=>{
          if(Object.keys(toastDisplay).length!==0){
            setTimeout(()=>{
              dispatch(setToast({}))
            },10000)
          }
          return Object.keys(toastDisplay).length!==0?<Toast message={toastDisplay.message} success={toastDisplay.success} />: null
        })()
        
      }
      {
      
        (()=>{
          if(Object.keys(modal).length!==0){
            setTimeout(()=>{
              dispatch(setToast({}))
            },10000)
          }
          return Object.keys(modal).length!==0?<Modal message={modal.message}/>: null
        })()
        
      }
    <div className={`${Object.keys(modal).length!==0?'opacity-20':''}`}>
    <Outlet />
    </div>
    

        
    </div>
  )
}

export default Layout