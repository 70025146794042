import React, { useState } from 'react'
import ErrorComponent from '../shared/error';
import { baseUrl, postMethod } from '../../util/shared';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../shared/input';
import Button from '../shared/button';
import Label from '../shared/label';
import Dropdown from '../shared/dropdown';
import ListElement from '../shared/listElement';
import { useDispatch, useSelector} from 'react-redux';
import { fetchProjects } from '../../util/stateChanges';


const AddProject = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("access");
    const dispatch = useDispatch();

    const [hardware, setHardware] = useState("");
    const [projectCategoryId, setProjectCategoryId] = useState();
    const [projectCategory, setProjectCategory] = useState("Select Project Category");
    const [name, setName] = useState("");
    const [password,setPassword] = useState("");
    const projectCategories = useSelector(state=>state.global.projectCategories)
    const [displayError, display] = useState(false);
    const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
    const [showProjectCategories,setShowProjectCategories] = useState(false)
    const [showCustomers,setShowCustomers] = useState(false)
    const customers = useSelector(state=>state.global.customers)
    const [customer,setCustomer] = useState("Select Customer")
    const [customerId,setCustomerId] = useState()
    const [projectSettingsSlot2, setProjectSettingsSlot2] = useState(""); 

    function selectProjectCategory(pc){
       setProjectCategoryId(pc.projectCategoryId)
       setProjectCategory(pc.projectCategoryName)
       toggleSelectProjectCategory()
    }

    function selectCustomer(c){
        setCustomerId(c.customerId)
        setCustomer(c.name)
        
        toggleSelectCutomers()
     }

     function toggleSelectCutomers(){
        setShowProjectCategories(false)
        setShowCustomers(!showCustomers)
    }

    function toggleSelectProjectCategory(){
        setShowCustomers(false)
        setShowProjectCategories(!showProjectCategories)
        
    }

 
    
    function addproject(e) {
      e.preventDefault();
      if (projectCategoryId==null) {
        setErrorMessage("Please Select A Valid Project Category!");
        display(true);
        return;
      }
      if (customerId == null) {
          setErrorMessage("Please Select A Valid Customer!");
          display(true);
          return;
      }
      const url = baseUrl + 'createproject';
      fetch(url, {
          method: postMethod,
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              projectName: name,
              customerId: customerId,
              password: password,
              hardware: hardware,
              projectCategoryId: projectCategoryId,
          }),
      })
      .then((response) => response.json())
      .then((data) => {
          if (data.projectId != null) {
              fetchProjects(dispatch);
              setProjectSettings(data.projectId); 
 
              navigate(
                  location?.state?.previousUrl
                      ? location.state.previousUrl
                      : '/projects'
              );
          } else {
              setErrorMessage("Unable to create project");
              display(true);
          }
      }).catch(e => {
          display(true);
          setErrorMessage(e);
      });
 }

    function setProjectSettings(projectId) {
      if (!projectSettingsSlot2) {
          setErrorMessage("Project settings Slot 2 value is missing!"); 
          display(true);
          return;
      }
      
      const url = baseUrl + 'setprojectsettings'; 
      fetch(url, {
          method: postMethod,
          headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json',
          }, 
          body: JSON.stringify({
              projectId: projectId, 
              slot: 2,
              value: projectSettingsSlot2, // ← Hier wird die `projectId` hinzugefügt
          }),
      })
      .then(response => {
        if (!response.ok) {
            throw new Error(`Failed to update project settings (Status: ${response.status})`);
        }
        return response.json();
    })
      .then(() => console.log("Project settings updated"))
      .catch(e => {
          setErrorMessage(e.message);
          display(true);
      });
  }


  return (
    <form className="space-y-4 md:space-y-6" id="project" onSubmit={addproject}>
      {displayError && <ErrorComponent message={{e:errorMessage}}/>}
      <div>
        <Label labelFor={"name"} content={"Project Name"} />
        <Input id={"name"} value={name} placeholder={"Project Name"} handler={(e)=>setName(e.target.value)} type={"text"} />
      </div>
      <div>
        <Label labelFor={"password"} content={"Password"} />
        <Input id={"password"} value={password} placeholder={"Password"} handler={(e)=>setPassword(e.target.value)} type={"password"} />
      </div>
      <div>
        <Label labelFor={"hardware"} content={"Hardware"} />
x        <Input id={"hardware"} value={hardware} placeholder={"Hardware"} handler={(e)=>setHardware(e.target.value)} type={"text"} />
      </div>
      
      <div className="w-full sm:mb-2 relative">
      <span className="span-container">Project Category</span>
        <Dropdown content={projectCategory} handle={toggleSelectProjectCategory} />
        { showProjectCategories && projectCategories && projectCategories.length > 0 && 
          <div id="dropdown" className="bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-600 absolute z-[1] w-full max-h-32 overflow-auto ">
          <div className="py-2 text-sm text-gray-700 dark:text-gray-200">
          {projectCategories && projectCategories.map((pc,idx)=><ListElement name={pc.projectCategoryName} element={pc} key={idx} handle={selectProjectCategory} />)}
          </div>
          </div>
       }
      </div>
      <div className="w-full sm:mb-2 relative">
      <span className="span-container">Customer</span>
      <Dropdown  content={customer} handle={toggleSelectCutomers} />
      { showCustomers && projectCategories && projectCategories.length > 0 && 
        <div id="dropdown" className="bg-white divide-y divide-gray-100 shadow w-44 dark:bg-gray-600 absolute z-[1] w-full overflow-auto max-h-28">
        <div className="py-2 text-sm text-gray-700 dark:text-gray-200">
        {customers && customers.map((pc,idx)=><ListElement name={pc.name} element={pc} key={idx} handle={selectCustomer} />)}
        </div>
        </div>
     }
      </div>
      <><div>
      <Label labelFor={"projectsettingsSlot2"} content={"Project Settings Slot 2"} />
      <Input id={"projectsettingsSlot2"} value={projectSettingsSlot2} placeholder={"Enter Slot 2 Value"} handler={(e)=>setProjectSettingsSlot2(e.target.value)} type={"text"} />
      </div></>

      <Button content={"Add Project"} />
    </form>
  )
  }
export default AddProject