
import { useNavigate } from 'react-router-dom';
import { deleteRequest } from '../../util/api';
import { fetchProjectCategories } from '../../util/stateChanges';
import { useDispatch, useSelector } from 'react-redux';
import { setModal, setToast } from '../../state';
import { useEffect, useState } from 'react';




const ProjectCategory = ({projectCategory,handler,toastFunction}) => {
   const navigate = useNavigate();
   const url = 'deleteprojectcategory/' + projectCategory.projectCategoryData.projectCategoryId
   const modal = useSelector(state=>state.global.modal)
   const usedProjectCategories = useSelector(state=>state.global.usedProjectCategories)
   const dispatch = useDispatch()
   const [isUsed,setIsUsed] = useState(false)
   function popover(){
     dispatch(setModal({message:"project category",loading:true, id:projectCategory.projectCategoryData.projectCategoryId}))
   }
   
   useEffect(()=>{
    if(usedProjectCategories){
      setIsUsed(usedProjectCategories.find(x=>Number(x)===Number(projectCategory.projectCategoryData.projectCategoryId)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
   },[usedProjectCategories])

   useEffect(()=>{
     if(modal && Number(modal.id)===Number(projectCategory.projectCategoryData.projectCategoryId)){
       if(modal.loading===false){
         if(modal.doAction ){
           deleteProjectCategory()
         }
         dispatch(setModal({}))
       }
     }
     // eslint-disable-next-line react-hooks/exhaustive-deps
   },[modal])
    function deleteProjectCategory(){
      deleteRequest(url).then(
        response=>{
          if(Number(response.status)===200){
            dispatch(setToast({message:"Deleted Successfully",success:true}))
            fetchProjectCategories(dispatch)
          }
          else if(Number(response.status)===401) {
            dispatch(setToast({message:"Unautorized! Please log in again",success:false}))
            
          }
          else {
            dispatch(setToast({message:"Unable to delete project category",success:false}))
            fetchProjectCategories(dispatch)
          }
        }
      )
    }
  return (
  
         <tr onClick={e=>{
          navigate('/viewprojectcategory/'+projectCategory.projectCategoryData.projectCategoryId)}} className="table-row">
        
        
               <td className="px-4 py-6 w-1/2 cursor-default">{projectCategory.projectCategoryData.projectCategoryName}</td>
               <td className="px-4 py-6 w-1/2 cursor-default">{projectCategory.projectCategoryData.projectCategoryId}</td>
               <td className="px-4 py-4 w-1/2 ">
                 <div className="flex-col lg:flex-row lg:space-x-2 items-center space-y-2 lg:space-y-0">
                   <button onClick={e=>{
                     e.stopPropagation()
                     navigate('/updateprojectcategory/'+projectCategory.projectCategoryData.projectCategoryId)}}
                     className="items-center px-2 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 focus:outline-none">
                     <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                         d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                     </svg>
                   </button>
                  
                   <button onClick={e=>{
                     e.stopPropagation()
                     popover()}}
                     className="items-center px-2 py-2 text-white bg-red-500 rounded-md hover:bg-red-600 focus:outline-none z-[1000]">
                     <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24"
                       stroke="currentColor">
                       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                         d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                     </svg>
                   </button>
                 </div>
               </td>
               
            </tr>
  )
}

export default ProjectCategory