import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Project from './project'
import Table from '../shared/table'


const Projects = () =>{
  const navigate = useNavigate()

  const projects = useSelector(state=>state.global.projects)
  const headerList = ['Project Name','Project Id','Customer Id','Category','Hardware','Action']
  return (
    <section className='page-container'>
      <Table heading={"Projects"} dataList={projects} component={(idx,project)=><Project key={idx} project={{projectData:project}} />} buttonContent={"Add Project"} handler={()=>navigate('/addproject')} headerList={headerList} />
    </section>
  )
}
export default Projects