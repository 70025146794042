import React from 'react'
import { useNavigate } from 'react-router-dom'
import Admin from './admin'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRequest } from '../../util/api'
import { setAdmins } from '../../state'
import Table from '../shared/table'

const Admins = () => {
    const url =  "listadmins"
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const admins = useSelector(state=>state.global.admins)
    const headerList = ['Admin Name','Email','Admin Id','Action']
    function updateList(){
      fetchRequest(url).then(data=>dispatch(setAdmins(data)))
    }
    
    return (
    <section className='page-container'>
      <Table heading={"Admins"} dataList={admins} component={(idx,admin)=><Admin key={idx} admin={{adminData:admin}} handler={updateList}/>} buttonContent={"Add Admin"} handler={()=>navigate('/addadmin')} headerList={headerList} />
    </section>
    )
}

export default Admins