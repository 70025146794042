import React, { useState } from 'react'
import { baseUrl, postMethod } from '../../util/shared';
import { useLocation, useNavigate } from 'react-router-dom';

import ErrorComponent from '../shared/error';
import { useDispatch } from 'react-redux';
import { setAdmin } from '../../state';
import Label from '../shared/label'
import Input from '../shared/input'
import Button from '../shared/button';
import Logo from '../../Folivora_Icon-white-green-linear_gradient_optimized.svg';


function Login() {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [displayError, display] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Unexpected Error!")
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch()

  function login(e) {
      e.preventDefault();
      const url = baseUrl + 'admin/login';
      fetch(url, {
          method: postMethod,
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              email: email,
              password: password,
          }),
      })
          .then((response) => {

              return response.json();
          })
          .then((data) => {
              if(data.access_token != null){
                localStorage.setItem('access', data.access_token);
                localStorage.setItem('adminId',data.admin.adminId)
                dispatch(setAdmin(data.admin.adminId))
                
                navigate(
                    location?.state?.previousUrl
                        ? location.state.previousUrl
                        : '/customers'
                );
              }
              else {
                setErrorMessage(msg=>"Invalid Credentials!")
                display(displayError=>true)
              }
              
              
          }).catch(e=>{
            display(displayError=>true)
            setErrorMessage(msg=>{"Connection to the server failed!"})
          });
  }
  return (
    <section className="bg-primary-main-background dark:bg-gray-900">
  <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
  <img src={Logo} alt="Folivora Logo" className="w-20 h-20 mb-4" /> 
  <a href="http://folivoraenergy.com" className="flex items-center mb-6 text-4xl font-semibold text-white dark:text-white" >
  Folivora Energy 
  </a>
     
      <div className="w-full bg-primary-nav rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-700 dark:border-gray-700">
      
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-white md:text-2xl dark:text-white">
                  Sign-in to your account
              </h1>
              <form className="space-y-4 md:space-y-6" id="customer" onSubmit={login}>
              {displayError && <ErrorComponent message={{e:errorMessage}}/>}
                  <div>
                      <Label labelFor={"email"} content={"Email"} />
                        <Input value={email} id={"email"} placeholder={"name@company.com"} type={"email"} handler={(e) => {setEmail(e.target.value)}} />
                  </div>
                  <div>
                      <Label labelFor={"password"} content={"Password"} />
                      <Input id={"password"} type={"password"} placeholder={"Password"} handler={(e) => {
                        setPassword(e.target.value)
                    }} />

                  </div>
                  <div className="flex items-center justify-between">
                     
                  </div>
                  <Button className="btn-primary" content={"Sign in"} />
              </form>
          </div>
      </div>
  </div>
</section>

  )
}

export default Login